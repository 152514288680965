/* .react-datepicker-wrapper input[type="text"]{
    border: none;
}

.backtest-input{
    border: none !important;
} */

.rdt_TableCol div:first-child {
    white-space: normal !important;
    overflow: visible !important;
}