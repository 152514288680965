.react {
	&-list {
		display: flex;
		align-items: center;
		margin: -0.25rem;
		padding: 0;
		li{
			display: inline-block;
			vertical-align: middle;
			line-height: 1.3;
			padding: 0.25rem;
		}
		.title {
			text-transform: uppercase;
			color: $base-light;
			letter-spacing: .05em;
			font-size: $fx-sz-12;
			font-weight: $fw-medium;
			margin-right: 1rem;
		}
		a {
			color: $light-400;
			display: inline-block;
			.icon {
				font-size: 1.5rem;
			}
		}
	}
	&-good {
		a:hover {
			color: $success;
		}
	}
	&-ok {
		a:hover {
			color: $accent-color;
		}
	}
	&-bad {
		a:hover {
			color: $warning;
		}
	}
}

@media (max-width:359px) {
	.react {
		&-list {
			flex-wrap: wrap;
			.title {
				width: 100%;
			}
		}
	}
}