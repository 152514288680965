.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 190px);
  width: 100%;
}

.dndflow .node{
  cursor: grab;
  width: 200px;
}

.dndflow .order{
  cursor: grab;
  width: 240px;
}

.dndflow .explain{
  cursor: grab;
  width: 300px;
}

.dndflow .condition-node{
  cursor: grab;
  /* width: 600px; */
}

.dndflow .exit-strategy{
  width: 300px;
}

.dndflow .rule-set-node{
  cursor: grab;
  width: 240px;
}

.dndflow .dndnode {
  border: 1px solid #1a192b;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: grab;
  text-align: center;
}

.rl {
  width: 6px;
  height: 14px;
  border-radius: 3px;
  background-color: #6576ff;
}

.tb {
  height: 6px;
  width: 14px;
  border-radius: 3px;
  background-color: #6576ff;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }
}


.nodebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.nodebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}


.context-menu {
  background: white;
  border-style: solid;
  border: none;
  position: absolute;
  z-index: 10;
}

.context-menu button {
  border: none;
  display: block;
  padding: 0.5em;
  text-align: left;
  width: 100%;
}

.dropdown-menu{
  margin: 0px !important;
  box-shadow: unset;
}

.node :global .react-flow__resize-control.handle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.react-select__menu {
  z-index: 1001 !important;
}

.react-flow__node-custominput .react-flow__handle {
  background: #e6e6e9;
}

.react-flow__node-custominput {
  background: #fff;

}

.react-flow__handle-connecting {
  background: #ff6060;
}

.react-flow__handle-valid {
  background: #55dd99;
}

.tooltip {
  pointer-events: none;
}